/* eslint-disable */
import React from 'react';
import Card from 'react-bootstrap/Card';
import './wikiSummary.scss';

interface WikiProps {
	html: string;
}

class WikiSummary extends React.Component<WikiProps, any> {

	constructor(props: WikiProps | Readonly<WikiProps>) {
		super(props);
	}

	render() {
		return (
			<>
				<h6 className="text-uppercase text-white-50 summary">Summary</h6>
				<Card.Text id="wiki-summary" dangerouslySetInnerHTML={{__html: this.props.html}}>
				</Card.Text>
			</>);
	}

}

export default WikiSummary;
