import React, {ReactNode} from 'react';
import {connect} from 'react-redux';
import SWHelper from '../../assets/sw_helpers';
import {StoreState} from '../../redux/store/storeType';
import Card from 'react-bootstrap/Card';
import {setSelectedPackage, setSelection} from '../../redux/actions';
import {StellarAction} from '../../redux/constants';
import './selectedInfo.scss';
import deepEqual from '../../util/deepEqual';
import StarRegistered from './starRegistered/starRegistered';
import SkySource from './skySource/skySource';
import {StarPackage} from '../../models/star-package.model';
import {SweObj} from '../../models/swe.model';
import {Solo} from '../../models/solo.model';
import {SkyModel} from '../../models/skymodel.model';
import TwinCard from '../TwinCard/TwinCard';
import ReactDOM from 'react-dom';

// 27640227 & 36458751
// Twin : 97462185
// SOLO large text: 45103264
interface SelectedState {
	zoomTimeout: any;
	hide: boolean;
	otherTwin: Solo | null;
}

interface SelectedProps {
	selectedPackage: StarPackage;
	selection: (SweObj & SkyModel) | (SweObj & Solo) | null;
	stel: any;
	hideNav: boolean;
	setSelectedPackage: (obj: StarPackage) => StellarAction<StarPackage>
	setSelection: (obj: (SweObj & SkyModel) | (SweObj & Solo) | null) => StellarAction<(SweObj & SkyModel) | (SweObj & Solo) | null>
}

class SelectedInfo extends React.Component<SelectedProps, SelectedState> {

	constructor(props: any) {
		super(props);
		this.state = {
			hide: false,
			zoomTimeout: null,
			otherTwin: null
		}
	}

	render() {
		return (
			<>
				{this.otherTwinName() ? (
					<TwinCard isHidden={this.state.hide} goToTwin={() => this.goToTwin()}
					          starName={this.otherTwinName()!}/>) : null}
				<Card id="selected-panel" onClick={() => {
					if (this.state.hide) {
						this.show();
					}
				}} bg="dark" className={this.getPanelClass()}>
					{this.matchInfoComponent()}
					{this.getToggleBtn()}
					{this.state.hide ? (<span id="hidden-title"
					                          className={this.title().length > 10 ? 'many' : 'few'}>
						{this.title()}</span>) : null}
				</Card>
			</>
		);
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside.bind(this));
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside.bind(this));
		window.addEventListener('mouseup', this.stopZoom.bind(this));
	}

	handleClickOutside(event: Event) {
		const domNode = ReactDOM.findDOMNode(document.getElementById('twin-panel'));
		const domNode1 = ReactDOM.findDOMNode(document.getElementById('selected-panel'));

		if ((!domNode || !domNode.contains(event.target as Node)) && (!domNode1 || !domNode1.contains(event.target as Node))) {
			this.hide();
		}
	}

	otherTwinName(): string | null {
		if (!SWHelper.isTwin(this.props.selectedPackage)) {
			return null;
		}
		return this.props.selectedPackage.viewing?.starName === this.props.selectedPackage.starName ?
			this.props.selectedPackage.starName2 :
			this.props.selectedPackage.starName;
	}

	async goToTwin(): Promise<void> {
		if (!SWHelper.isTwin(this.props.selectedPackage)) {
			return;
		}
		return SWHelper.selectTwin(this.props.selectedPackage, this.props);
	}

	getToggleBtn(): ReactNode {
		return this.state.hide ?
			(<i className="bi-arrow-right toggle-btn"
			    onClick={() => this.show()}/>) :
			(<i className="bi-arrow-left toggle-btn"
			    onClick={() => this.hide()}/>);
	}

	getPanelClass(): string {
		if (this.props.hideNav) {
			return 'hide full-hide';
		}
		const openClass = 'open text-white shadow-lg';
		const closedClass = 'text-white shadow-lg';
		const hiddenClass = 'hide text-white shadow-lg';
		if (this.state.hide && this.props.selectedPackage) {
			return hiddenClass;
		}
		if (this.props.selectedPackage) {
			return openClass;
		}
		return closedClass;
	}

	hide(): void {
		this.setState({hide: true});
	}

	show(): void {
		this.setState({hide: false});
	}

	matchInfoComponent(): ReactNode {
		if (!this.props.selectedPackage) {
			return null;
		}
		if (SWHelper.isSolo(this.props.selectedPackage) || SWHelper.isTwin(this.props.selectedPackage)) {
			return (<StarRegistered
				isHidden={this.state.hide}
				selectTwin={() => this.goToTwin()}
				twin={SWHelper.isTwin(this.props.selectedPackage) ? this.props.selectedPackage : undefined}
				object={SWHelper.isSolo(this.props.selectedPackage) ? this.props.selectedPackage : this.props.selectedPackage.viewing!}/>);
		}
		return (<SkySource object={this.props.selectedPackage as SkyModel}
		                   stel={this.props.stel}
		                   isHidden={this.state.hide}
		                   otherNames={this.otherNames()} title={this.title()}/>);
	}

	async componentDidUpdate(prevProps: Readonly<SelectedProps>, prevState: Readonly<any>, snapshot?: any) {
		if (prevProps !== this.props) {
			if (!deepEqual(prevProps.selection, this.props.selection)) {
				await this.selectionChanged();
			}
		}
	}

	title(): any {
		if (SWHelper.isTwin(this.props.selectedPackage)) {
			return this.props.selectedPackage?.viewing?.starName || '';
		}
		if (SWHelper.isSolo(this.props.selectedPackage)) {
			return this.props.selectedPackage.starName || '';
		}
		return this.props.selectedPackage ? this.otherNames()?.[0] ?? 'Selection' : 'Selection';
	}

	otherNames(): any[] {
		return this.props.selectedPackage ? SWHelper.namesForSkySource(this.props.selectedPackage as any, 26) : [];
	}

	zoomInButtonEnabled() {
		return !(!this.props.stel.lock || !this.props.selectedPackage);
	}

	async selectionChanged(): Promise<void> {
		const obj = this.props.selection;
		if (!obj) {
			return;
		}
		this.hide();
		const ref = (obj.v === this.props.selectedPackage?.v) && (this.props.selectedPackage as any)?.referenceNumber;
		return SWHelper.getPackageFromSelection(obj, ref).then(async res => {
			if (res) {
				this.props.setSelectedPackage(res);
				this.props.setSelection(obj);
			}
			setTimeout(() => this.show(), 500);
		}, err => {
			this.hide();
			console.log('Couldn\'t find info for object ' + obj + ':' + err)
			this.props.setSelectedPackage(null);
			this.props.setSelection(null);
			this.setState({otherTwin: null});
		});
	}

	zoomInButtonClicked(): void {
		const currentFov = this.props.stel.fov * 180 / Math.PI
		SWHelper.$stel.zoomTo(currentFov * 0.3 * Math.PI / 180, 0.4);
		this.setState({
			zoomTimeout: setTimeout(_ => {
				this.zoomInButtonClicked()
			}, 300)
		});
	}

	stopZoom(): void {
		if (this.state.zoomTimeout) {
			clearTimeout(this.state.zoomTimeout);
			this.setState({
				zoomTimeout: null
			});
		}
	}

}

const mapStatetoProps = (state: StoreState) => {
	return {
		stel: state.stel,
		selectedPackage: state.selectedPackage,
		selection: state.selection,
		hideNav: state.hideNav
	}
};
const mapDispatchtoProps = {
	setSelectedPackage,
	setSelection,
};
export default connect(mapStatetoProps, mapDispatchtoProps)(SelectedInfo);
