import React, {ReactNode} from 'react';
import {FormControl, InputGroup, ListGroup, ListGroupItem} from 'react-bootstrap';
import ReactDOM from 'react-dom';
import SWHelper from '../../assets/sw_helpers';
import {connect} from 'react-redux';
import {setSearchFocused, setSelectedPackage, setSelection} from '../../redux/actions';
import './targetSearch.scss';
import {Solo} from '../../models/solo.model';
import {Twin} from '../../models/twin.model';
import {SkyModel} from '../../models/skymodel.model';
import {SweObj} from '../../models/swe.model';
import {StellarAction} from '../../redux/constants';
import {Registered} from '../../models/star-package.model';
import {StoreState} from '../../redux/store/storeType';
import {withTranslation} from 'react-i18next';
import {i18n, TFunction} from 'i18next';

interface SearchState {
	autoCompleteChoices: (Solo | Twin)[];
	lastQuery: string;
	smallScreen: boolean;
	hoveringKey: string
}

interface SearchProps {
	setSelection: (obj: (SweObj & SkyModel) | (SweObj & Solo) | null) => StellarAction<(SweObj & SkyModel) | (SweObj & Solo) | null>,
	setSelectedPackage: any;
	selection: (SweObj & SkyModel) | (SweObj & Solo) | null;
	setSearchFocused: (val: boolean) => StellarAction<boolean>;
	t: TFunction;
	i18n: i18n;
}

class TargetSearch extends React.Component<SearchProps, SearchState> {
	private readonly searchControlRef: React.RefObject<any>;

	constructor(props: any) {
		super(props);
		this.state            = {
			autoCompleteChoices: [],
			smallScreen: false,
			lastQuery: '',
			hoveringKey: ''
		}
		this.searchControlRef = React.createRef();
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside.bind(this));
		this.handleResize(null);
		window.addEventListener('resize', this.handleResize.bind(this));
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside.bind(this));
		window.removeEventListener('resize', this.handleResize.bind(this));
	}

	handleClickOutside(event: Event) {
		const domNode = ReactDOM.findDOMNode(document.getElementById('star-search'));

		if (this.state.lastQuery && (!domNode || !domNode.contains(event.target as Node))) {
			this.resetSearch();
		}
	}

	handleResize(event: Event | null) {
		this.setState({smallScreen: window?.matchMedia('(max-width: 800px)')?.matches});
	}

	render(): ReactNode {
		return (
			<div className="d-inline-flex position-relative" id="star-search">
				<InputGroup size={this.state.smallScreen ? undefined : 'lg'}>
					<InputGroup.Prepend>
						<InputGroup.Text id="basic-addon1">
							<i className="bi-search"/>
						</InputGroup.Text>
					</InputGroup.Prepend>
					<FormControl
						placeholder={this.props.t('Enter a star reference')}
						ref={this.searchControlRef}
						onKeyPress={(event: any) => {
							if (event?.key === 'Enter') {
								if (this.state.autoCompleteChoices.length) {
									const res = this.state.autoCompleteChoices[0];
									if (SWHelper.isSolo(res)) {
										return this.selectSolo(res);
									}
									return this.selectTwin(res);
								}
							}
						}}
						onBlur={() => this.props.setSearchFocused(false)}
						onFocus={() => this.props.setSearchFocused(true)}
						onChange={(text) => {
							if (text.target.value === '') {
								this.resetSearch();
								return;
							}
							this.refresh(text.target.value);
						}}
						aria-label={this.props.t('Enter a star reference')}
						aria-describedby="basic-addon1"
					/>
				</InputGroup>
				{this.renderAutocomplete()}
			</div>
		);
	}

	renderAutocomplete() {
		return this.showList() ? (<ListGroup className="bg-dark shadow-lg" id="star-dropdown">
			{this.state.autoCompleteChoices?.map((choice: Solo | Twin) => SWHelper.isSolo(choice as any) ?
				this.renderSoloItem(choice as Solo) :
				this.renderTwinItem(choice as Twin))}</ListGroup>) : null;
	}

	renderTwinItem(choice: Twin) {
		return (<ListGroupItem
			className="bg-dark d-flex flex-row align-items-center align-content-center justify-content-start position-relative"
			action
			onMouseEnter={() => this.hoverItem(choice.referenceNumber?.toString())}
			onMouseLeave={() => this.unhoverItem()}
			key={choice?.referenceNumber}
			onClick={() => this.selectTwin(choice)}>
			<div style={{verticalAlign: 'middle'}} className="d-inline-flex star-logo">
				{this.renderTwinLogo()}
			</div>
			<div className="d-inline-block w-50 m-0 text-white flex-column star-info">
				<div>
					<strong className="star-name">{choice.starName} <span
						className="text-white-50 font-weight-light">&</span> {choice.starName2}</strong>
				</div>
				<span className="d-none d-md-flex">
                    <span className="star-color font-weight-bold">{this.props.t('Twin Stars')}</span>
                </span>
			</div>
			{!this.state.smallScreen && (this.state.hoveringKey === choice.referenceNumber?.toString()) ? (
				this.renderArrowRight()) : null}
		</ListGroupItem>);
	}

	hoverItem(key: string): void {
		this.setState({hoveringKey: key});
	}

	unhoverItem(): void {
		this.setState({hoveringKey: ''});
	}

	renderSoloItem(choice: Solo) {
		return (
			<ListGroupItem
				className="bg-dark d-flex flex-row align-items-center align-content-center justify-content-start position-relative"
				action
				onMouseEnter={() => this.hoverItem(choice.referenceNumber?.toString())}
				onMouseLeave={() => this.unhoverItem()}
				key={choice?.referenceNumber}
				onClick={() => this.selectSolo(choice)}>
				<div style={{verticalAlign: 'middle'}} className="d-inline-flex star-logo">
					{this.renderStarLogo()}
				</div>
				<div className="d-inline-block m-0 text-white flex-column star-info">
					<div>
						<strong className="star-name">{choice.starName}</strong>
					</div>
					<span className="font-weight-light d-none d-md-flex">
                    {choice.constellation}&nbsp;&middot;&nbsp;{choice.starDedicatedTo}
                </span>
				</div>
				{!this.state.smallScreen && (this.state.hoveringKey === choice.referenceNumber?.toString()) ? (
					this.renderArrowRight()) : null}
			</ListGroupItem>);
	}

	renderTwinLogo() {
		return (
			<i style={{fontSize: '22px', lineHeight: '22px', height: '22px'}} className="bi-stars ml-2 star-color"/>);
	}

	renderStarLogo() {
		return (
			<i style={{fontSize: '22px', lineHeight: '22px', height: '22px'}}
			   className="bi-star-fill ml-2 star-color"/>);
	}

	renderArrowRight() {
		return (
			<div className="d-inline-flex text-white jello ml-5"
			     style={{width: '15%', verticalAlign: 'middle'}}>
				<i style={{fontSize: '22px', lineHeight: '22px', height: '22px'}} className="bi-arrow-right-circle"/>
			</div>);
	}

	showList() {
		return this.state.lastQuery;
	}

	selectSolo(val: Solo): void {
		SWHelper.selectSolo(val, (v: number) => this.props.setSelectedPackage({...val, v}));
		this.resetSearch();
	}

	async selectTwin(val: Twin): Promise<void> {
		await SWHelper.selectTwin(val, this.props);
		this.resetSearch();
	}

	resetSearch(): void {
		this.setState({
			autoCompleteChoices: [],
			lastQuery: ''
		});
		if (this.searchControlRef.current) {
			this.searchControlRef.current.value = '';
		}
	}

	refresh = (text: string) => {
		let str = text;
		str     = str.toUpperCase();
		str     = str.replace(/\s+/g, '');
		if (this.state.lastQuery === str) {
			return;
		}
		this.setState({lastQuery: str});
		SWHelper.getPackageByRef(+str).then((result: Solo | Twin | null) => {
			this.setState({autoCompleteChoices: result ? [result] : []});
		}, err => {
			console.log(err)
		})
	}

	typeToName(t: any) {
		return SWHelper.nameForSkySourceType(t)
	}

	iconForSkySource(s: Registered) {
		return SWHelper.iconForSkySource(s as SkyModel);
	}

}

const mapStatetoProps    = (state: StoreState) => {
	return {
		selection: state.selection
	}
}
const mapDispatchtoProps = {
	setSelection,
	setSearchFocused,
	setSelectedPackage
};
export default withTranslation('Content')(connect(mapStatetoProps, mapDispatchtoProps)(TargetSearch));
