import './starRegistered.scss';
import React from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import SWHelper from '../../../assets/sw_helpers';
import Alert from 'react-bootstrap/Alert';
import {Solo} from '../../../models/solo.model';
import {Twin} from '../../../models/twin.model';
import {connect} from 'react-redux';
import {setSelectedPackage, setSelection} from '../../../redux/actions';
import {StoreState} from '../../../redux/store/storeType';
import {SweObj} from '../../../models/swe.model';
import {SkyModel} from '../../../models/skymodel.model';
import {withTranslation} from 'react-i18next';
import {TFunction} from 'i18next';

interface StarProps {
	object: Solo;
	twin?: Twin;
	selection: (SweObj & SkyModel) | (SweObj & Solo) | null;
	setSelection: any;
	selectTwin: () => any;
	stel: any;
	setSelectedPackage: any;
	isHidden: boolean;
	t: TFunction;
}

interface StarState {
	copied: boolean,
	smallScreen: boolean
}

class StarRegistered extends React.Component<StarProps, StarState> {

	constructor(props: StarProps) {
		super(props);
		this.state = {
			copied: false,
			smallScreen: false
		}
	}

	getCardHead() {
		return (
			<>
				<div className="flex-row d-flex justify-content-between align-items-start mb-2">
					<div className="w-25 d-inline-flex" id="image-container">
						<Card.Img src={this.icon()} id="card-img"
						          onLoad={() => this.showImg()}
						          onError={() => this.hideCardImg()}/>
					</div>
					<div className={'d-inline-flex flex-column' + (this.props.isHidden ? ' is-hidden' : '')}
					     id="stats">
						<div className="w-100 d-flex flex-row stat">
							<strong className="name">{this.props.t('Right Ascension')}</strong>&nbsp;
							<span className="value d-inline-flex flex-row">{this.props.object?.rightAscension}</span>
						</div>
						<div className="w-100 d-flex flex-row stat">
							<strong className="name">{this.props.t('Declination')}</strong>&nbsp;
							<span className="value d-inline-flex flex-row">{this.props.object?.declination}</span>
						</div>
						{this.visibility()}
					</div>
				</div>
			</>);
	}

	icon(): string {
		return SWHelper.iconForSkySource(this.props.object as SkyModel);
	}

	render() {
		return (
			<>
				<Card.Body id="star-body">
					<div className="position-relative">
						<i className="bi-star-fill star-color panel-star-icon"/>
					</div>
					{this.getCardHead()}
					{this.props.twin ?
						(<span className="star-color-bg text-white text-shadow twin-badge"
						       style={{borderRadius: '10px', padding: '5px 10px'}}><i
							className="bi-stars"/>&nbsp;{this.props.t('Twin Stars')}</span>) : null}
					<Card.Title style={{fontSize: '32px'}}
					            className="mt-2 title">{this.props.object?.starName}</Card.Title>
					<Card.Subtitle className="subtitle">{this.props.object?.constellation}</Card.Subtitle>
					{this.renderContent()}
				</Card.Body>
				<Alert style={{position: 'absolute', bottom: '0', zIndex: 1000, left: '0'}} show={this.state.copied}
				       variant="success" dismissible
				       onClose={() => this.setState({copied: false})}>{this.props.t('Link Copied')}!</Alert>
				<input id="link_inputid" style={{position: 'absolute', left: '-200px'}} value={this.getShareLink()}
				       readOnly type="text"/>
			</>);
	}

	renderContent() {
		return !this.props.isHidden ? (<>
			{!this.props.twin ?
				(<Card.Text className="m-0 card-text">
					<span
						className="text-white-50 font-weight-light info">{this.props.t('Star dedicated to')}:&nbsp;</span>
					<strong
						className="star-color info">{this.props.object?.starDedicatedTo}</strong>
				</Card.Text>) : null}
			<Card.Text className="m-0 card-text">
				<span
					className="text-white-50 font-weight-light info">{this.props.t('Date of Registration')}:&nbsp;</span>
				<strong className="text-white info">{this.props.object?.dateOfRegistration}</strong>
			</Card.Text>
			<Card.Text className="m-0 card-text">
				<span className="text-white-50 font-weight-light info">{this.props.t('Reference Number')}:&nbsp;</span>
				<strong className="text-white info">{this.props.object?.referenceNumber}</strong>
			</Card.Text>
			{!this.props.isHidden ? (
				<>
					<Card.Text className="mt-4 mb-3 personal-message">
						<div className="font-weight-bold"
						     style={{whiteSpace: 'pre-wrap'}}>{this.getPersonalMessage()}</div>
					</Card.Text>
					<div className="mt-4 flex-row d-flex share-link">
						<Button variant="light"
						        className="font-weight-bold"
						        size={this.state.smallScreen ? 'sm' : undefined}
						        onClick={this.copyLink.bind(this)}>{this.props.t('Share Link')} <i
							className="bi-link-45deg"/></Button>
						{!SWHelper.$lastSelectionSweObj ? (<Button
							size={this.state.smallScreen ? 'sm' : undefined}
							className="ml-3 font-weight-bold"
							onClick={this.point.bind(this)}>{this.props.t('Point to Star')} <i
							className="bi-arrows-move"/></Button>) : null}
						{this.props.twin ? (<Button
							size={this.state.smallScreen ? 'sm' : undefined}
							variant="outline-warning font-weight-bold"
							className="ml-3"
							onClick={this.props.selectTwin}>{this.props.t('Go to Twin')} <i
							className="bi-arrow-right"/></Button>) : null}
						<Button
							size={this.state.smallScreen ? 'sm' : undefined}
							variant="secondary"
							className="ml-3 font-weight-bold"
							target="_blank"
							href={this.getBuyLink()}>{this.props.t('Name a Star')} <i
							className="bi-cart"/></Button>
					</div>
				</>) : null}
		</>) : null;
	}

	getBuyLink(): string {
		if (!this.props.object?.['website&Type']) {
			return 'https://www.world-star-register.co.uk';
		} // CONVENTION === check the start of the string for country code
		if ((this.props.object['website&Type'] as string).startsWith('UK')) {
			return 'https://www.world-star-register.co.uk';
		}
		return 'https://www.world-star-register.com';
	}

	getPersonalMessage(): string {
		let paras = [];
		if (this.props.twin) {
			for (const para of [this.props.twin.personalMessage, this.props.twin.personalMessage2, this.props.twin.personalMessage3, this.props.twin.personalMessage4]) {
				if (para) {
					paras.push(para);
				}
			}
		} else {
			for (const para of [this.props.object.personalMessage, this.props.object.personalMessage2,
				this.props.object.personalMessage3, this.props.object.personalMessage4]) {
				if (para) {
					paras.push(para);
				}
			}
		}
		return paras.join('\n\n');
	}

	point(event: any) {
		SWHelper.setSweObjAsSelection({...this.props.selection!, ...this.props.object});
	}

	componentDidMount() {
		this.handleResize(null);
		window.addEventListener('resize', this.handleResize.bind(this));
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize.bind(this));
	}

	handleResize(event: Event | null) {
		this.setState({smallScreen: window?.matchMedia('(max-width: 800px)')?.matches});
	}

	copyLink(event: any) {
		/* write to the clipboard now */
		const input: HTMLInputElement | null = document.querySelector('#link_inputid');
		input?.focus();
		input?.select();
		const selection = window.getSelection(); // Get the Selection object
		const range     = document.createRange(); // Create a new range
		range.selectNodeContents(input as Node); // Select the content of the node from line 1
		selection!.addRange(range); // Add the range to selection
		document.execCommand('copy'); // Execute the command
		const copied = document.execCommand('copy');
		this.setState({copied});
		// @ts-ignore
		window?.getSelection()?.removeAllRanges();
		setTimeout(() => this.setState({copied: false}), 5000);
	}

	getShareLink(): string {
		return this.props.object?.referenceNumber ? SWHelper.getShareLink(this.props.object) : '';
	}

	visibility() {
		const obj = SWHelper.$stel?.core?.selection || this.props.selection;
		if (!obj || !this.props.stel) {
			return null;
		}
		const visibility = SWHelper.computeVisibility(obj, this.props, this.props.t);
		return (<div className="w-100 d-flex flex-row stat">
			<strong className="name">{this.props.t('Visibility')}</strong>&nbsp;
			<span className="value d-inline-flex flex-row" dangerouslySetInnerHTML={{__html: visibility}}/>
		</div>);
	}

	hideCardImg() {
		const img = document.getElementById('card-img');
		if (!img) {
			return;
		}
		img.style.display = 'none';
	}

	showImg() {
		const img = document.getElementById('card-img');
		if (!img) {
			return;
		}
		img.style.display = '';
	}
}

const mapStatetoProps    = (state: StoreState) => {
	return {
		selection: state.selection,
		stel: state.stel
	}
}
const mapDispatchtoProps = {
	setSelection,
	setSelectedPackage
}
export default withTranslation('Content')(connect(mapStatetoProps, mapDispatchtoProps)(StarRegistered));
