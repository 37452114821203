import React, {ReactNode} from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import './bottomButton.scss';

export default class BottomButton extends React.Component<any, any> {

	constructor(props: { toggled: boolean, label: string, img: string, img_alt: string, clicked: (toggled: boolean, label: string) => void }) {
		super(props);
	}

	render(): ReactNode {
		return (
			<OverlayTrigger
				placement="right"
				delay={{show: 250, hide: 400}}
				overlay={<Tooltip id="button-tooltip">
					{this.props.label}
				</Tooltip>}>
				{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
				<a className={this.props.label.replace(' ', '-') + (this.props.toggled ? ' on bottom-button' : ' off bottom-button')}
				   onClick={() => this.props.clicked(!this.props.toggled, this.props.label)}>
					<img src={this.props.img} alt={this.props.label}/>
				</a>
			</OverlayTrigger>);
	}
}
