import {StoreState} from '../store/storeType';
import {AnyAction, combineReducers} from 'redux';
import {Solo} from '../../models/solo.model';
import {Twin} from '../../models/twin.model';

const defaultState: StoreState = {
	initComplete: false,
	stel: null,
	showPlanetsVisibilityDialog: false,
	showLocationDialog: false,
	selectedPackage: null,
	selection: null,
	searchFocused: false,
	showSidePanel: false,
	hideNav: false,
	timeZone: '',
	showMainToolBar: true,
	showLocationButton: true,
	showTimeButtons: true,
	showObservingPanelTabsButtons: true,
	showSelectedInfoButtons: true,
	showFPS: false,

	fullscreen: false,
	nightmode: false,
	wasmSupport: true,

	timeSpeed: 1,
	autoDetectedLocation: {
		short_name: 'Unknown',
		country: 'Unknown',
		street_address: '',
		lat: 0,
		lng: 0,
		alt: 0,
		accuracy: 5000
	},

	currentLocation: {
		short_name: 'Unknown',
		country: 'Unknown',
		street_address: '',
		lat: 0,
		lng: 0,
		alt: 0,
		accuracy: 5000
	},

	useAutoLocation: true
};

function wasmSupport(state = defaultState.wasmSupport, action: AnyAction) {
	switch (action.type) {
		case 'TOGGLE_WASM_SUPPORT':
			return action.payload;
		default:
			return state;
	}
}

function timeSpeed(state = 1, action: AnyAction) {
	switch (action.type) {
		case 'SET_TIME_SPEED':
			return action.payload || 1;
		default:
			return state;
	}
}

function searchFocused(state = defaultState.searchFocused, action: AnyAction) {
	switch (action.type) {
		case 'SET_INPUT_FOCUSED':
			return !!action.payload;
		default:
			return state;
	}
}

function stel(state = defaultState.stel, action: AnyAction) {
	switch (action.type) {
		case 'UPDATE_STEL':
			return action.payload || null;
		default:
			return state;
	}
}

function initComplete(state = defaultState.initComplete, action: AnyAction) {
	switch (action.type) {
		case 'INIT_COMPLETE':
			return action.payload;
		default:
			return state;
	}
}

function hideNav(state = defaultState.hideNav, action: AnyAction) {
	switch (action.type) {
		case 'TOGGLE_HIDE_NAV':
			return !!action.payload;
		default:
			return state;
	}
}

function selection(state = 0, action: AnyAction) {
	switch (action.type) {
		case 'SET_SELECTION':
			return action.payload || state;
		default:
			return state;
	}
}

function autoDetectedLocation(state = defaultState.autoDetectedLocation, action: AnyAction) {
	switch (action.type) {
		case 'SET_AUTO_LOCATION':
			return action.payload || null;
		default:
			return state;
	}
}

function currentLocation(state = defaultState.currentLocation, action: AnyAction) {
	switch (action.type) {
		case 'SET_CURRENT_LOCATION':
			return action.payload || null;
		default:
			return state;
	}
}

function useAutoLocation(state = defaultState.useAutoLocation, action: AnyAction) {
	switch (action.type) {
		case 'SET_USE_AUTO_LOCATION':
			return action.payload || null;
		default:
			return state;
	}
}

function timeZone(state = defaultState.timeZone, action: AnyAction) {
	switch (action.type) {
		case 'SET_TIME_ZONE':
			return action.payload || null;
		default:
			return state;
	}
}

function selectedPackage(state: Solo | Twin | null = defaultState.selectedPackage, action: AnyAction) {
	switch (action.type) {
		case 'SET_SELECTED_PACKAGE':
			return action.payload || null;
		default:
			return state;
	}
}

export default combineReducers<StoreState>({
	wasmSupport,
	initComplete,
	stel,
	selectedPackage,
	currentLocation,
	timeSpeed,
	autoDetectedLocation,
	useAutoLocation,
	timeZone,
	selection,
	hideNav,
	searchFocused
});
