import {animated, useSpring} from 'react-spring';
import React from 'react';

const calc = (x: number, y: number) => [-(y - window.innerHeight / 2) / 20, (x - window.innerWidth / 2) / 20, 1.1]
const trans = (x: any, y: any, s: any) => `perspective(600px) scale(${2 - s})`

export default function ElevateSpring(props: any) {
	const [values, set] = useSpring(() => ({xys: [0, 0, 1], config: {mass: 5, tension: 300, friction: 50}}))
	return (
		<animated.div
			onMouseMove={({clientX: x, clientY: y}) => set({xys: calc(x, y)})}
			onMouseLeave={() => set({xys: [0, 0, 1]})}
			style={{transform: values?.xys ? values.xys.interpolate(trans as any) : undefined}}
		>
			{props.children}
		</animated.div>
	)
}
