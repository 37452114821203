import React from 'react';
import BottomButton from '../bottomButton/bottomButton';
import {connect} from 'react-redux';
import {StoreState} from '../../redux/store/storeType';
import './bottomBar.scss';
import SWHelper from '../../assets/sw_helpers';

type Toggles = 'Constellations' | 'Constellations Art' | 'Atmosphere' |
    'Landscapes' | 'Equatorial Grid' | 'Deep Sky Objects';

interface StellarButton {
    label: Toggles;
    img: string;
}

class BottomBar extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            buttons: [
                {label: 'Constellations', img: 'images/btn-cst-lines.svg'},
                {label: 'Constellations Art', img: 'images/btn-cst-art.svg'},
                {label: 'Atmosphere', img: 'images/btn-atmosphere.svg'},
                {label: 'Landscapes', img: 'images/btn-landscape.svg'},
                {label: 'Equatorial Grid', img: 'images/btn-equatorial-grid.svg'},
                {label: 'Deep Sky Objects', img: 'images/btn-nebulae.svg'}] as StellarButton[]
        }
    }

    render() {
        return (
            <div id="bottomBar"
                 className="d-flex justify-content-around"> {this.state.buttons.map((b: StellarButton) => (
                <BottomButton key={b.label} label={b.label}
                              img={b.img}
                              toggled={this.getToggled(b.label)}
                              clicked={() => this.toggleButton(b.label)}/>))}
            </div>);
    }

    getToggled(label: Toggles): boolean {
        switch (label) {
            case 'Equatorial Grid':
                return this.props.stel?.lines?.equatorial?.visible;
            case 'Constellations Art':
                return this.props.stel?.constellations?.images_visible;
            case 'Constellations':
                return this.props.stel?.constellations?.lines_visible;
            case 'Atmosphere':
                return this.props.stel?.atmosphere?.visible;
            case 'Landscapes':
                return this.props.stel?.landscapes?.visible;
            case 'Deep Sky Objects':
                return this.props.stel?.dsos?.visible;
            default:
                return false;
        }
    }

    toggleButton(label: Toggles) {
        const toggled = !this.getToggled(label);
        switch (label) {
            case 'Equatorial Grid':
                SWHelper.$stel.core.lines.equatorial.visible = toggled;
                return;
            case 'Constellations Art':
                SWHelper.$stel.core.constellations.images_visible = toggled;
                return;
            case 'Constellations':
                SWHelper.$stel.core.constellations.lines_visible = toggled;
                SWHelper.$stel.core.constellations.labels_visible = toggled;
                return;
            case 'Atmosphere':
                SWHelper.$stel.core.atmosphere.visible = toggled
                return;
            case 'Landscapes':
                SWHelper.$stel.core.landscapes.visible = toggled;
                return;
            case 'Deep Sky Objects':
                SWHelper.$stel.core.dsos.visible = toggled;
                return;
        }
    }

}

const mapStatetoProps = (state: StoreState) => ({
    stel: state.stel
});

export default connect(mapStatetoProps)(BottomBar);
