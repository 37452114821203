import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import en from '../translation/en.json';
import es from '../translation/es.json';
import fr from '../translation/fr.json';
import de from '../translation/de.json';

i18n.use(initReactI18next).init({
	resources: {
		en,
		es,
		fr,
		de
	},
	lng: window.location.pathname.replace('/', ''),
	fallbackLng: 'en',
	returnNull: false,
	keySeparator: false,
	debug: true,
	interpolation: {
		escapeValue: false,
	},
});

export default i18n;
