import React, {ReactNode} from 'react';
import SWHelper, {DDDate} from './assets/sw_helpers';
import {connect} from 'react-redux';
import {GeoLocation, StoreState} from './redux/store/storeType';
import {
	initComplete,
	setAutoDetectedLocation,
	setCurrentLocation,
	setSelectedPackage,
	setSelection,
	setUseAutoLocation,
	toggleWasmSupport,
	updateStel
} from './redux/actions';
import BottomBar from './components/bottomBar/bottomBar';
import LocationDialog from './components/locationDialog/locationDialog';
import Toolbar from './components/toolbar/toolbar';
import {StellarAction} from './redux/constants';
import StelWebEngine from './assets/js/stellarium-web-engine';
import _ from 'lodash';
import DatePicker from './components/dPicker/dPicker';
import SelectedInfo from './components/selectedInfo/selectedInfo';
import {RouteProps} from 'react-router';
import RingLoader from 'react-spinners/RingLoader';
import deepEqual from './util/deepEqual';
import {SweObj} from './models/swe.model';
import {SkyModel} from './models/skymodel.model';
import {Solo} from './models/solo.model';
import {StarPackage} from './models/star-package.model';
import {Twin} from './models/twin.model';
import Language from './components/language/language';

interface AppDispatchProps {
	setCurrentLocation: (newValue: GeoLocation) => StellarAction<any>;
	setUseAutoLocation: (newValue: boolean) => StellarAction<any>;
	toggleWasmSupport: (newValue: boolean) => StellarAction<any>;
	initComplete: (newValue: boolean) => StellarAction<boolean>;
	setAutoDetectedLocation: (newValue: GeoLocation) => StellarAction<GeoLocation>;
	setSelection: (newValue: (SweObj & SkyModel) | (SweObj & Solo) | null) => StellarAction<(SweObj & SkyModel) | (SweObj & Solo) | null>;
	setSelectedPackage: any;
	updateStel: (newValue: any) => StellarAction<any>;
}

interface AppStateProps {
	stel: any;
	timeSpeed: number;
	selection: any;
	timeZone: string;
	currentLocation: GeoLocation;
	autoDetectedLocation: GeoLocation;
	useAutoLocation: boolean;
}

interface AppState {
	startTimeIsSet: boolean,
	initDone: boolean,
	timeRef: any,
	dataSourceInitDone: boolean
}


class App extends React.Component<AppDispatchProps & AppStateProps & RouteProps, AppState> {
	private readonly canvasRef: React.RefObject<any>;

	constructor(props: any) {
		super(props);
		this.state     = {
			startTimeIsSet: false,
			initDone: false,
			timeRef: undefined,
			dataSourceInitDone: false
		};
		this.canvasRef = React.createRef();
	}

	render(): ReactNode {
		return (
			<div className="container-fluid" style={{padding: '0'}}>
				<div
					className={`loader d-flex flex-column justify-content-center align-items-center ${(this.state.dataSourceInitDone && this.state.initDone) ? 'loaded' : 'loading'}`}>
					<RingLoader color="rgba(255,200,0,0.8)"
					            loading={!this.state.dataSourceInitDone || !this.state.initDone} size={150}/>
					<img height="30" id="loading-logo"
					     src="images/logo-white.png"
					     className="mt-4"
					     alt="WSR StarFinder Web Logo"/>
					<h4 className="text-white h4">WSR StarFinder</h4>
				</div>
				<Toolbar/>
				<div id="stel">
					<div style={{position: 'relative', width: '100%', height: '100%'}}>
						<canvas id="stel-canvas" ref={this.canvasRef}/>
					</div>
				</div>
				<SelectedInfo/>
				<LocationDialog/>
				<BottomBar/>
				<Language/>
				<DatePicker value={SWHelper.getLocalTime()}/>
			</div>
		);
	}

	// When props change other component
	async componentDidUpdate(prevProps: AppStateProps & RouteProps) {
		if (!this.props.stel) {
			return;
		}
		const currentLoc = !deepEqual(this.props.currentLocation, prevProps.currentLocation) && !this.props.useAutoLocation;
		const autoLoc    = !deepEqual(this.props.autoDetectedLocation, prevProps.autoDetectedLocation) && !currentLoc;
		if (currentLoc || autoLoc) {
			console.log(this.props.currentLocation, this.props.autoDetectedLocation);
			this.storeCurrentLocation(this.props.currentLocation, autoLoc ? this.props.autoDetectedLocation : undefined);
		}
		if (!deepEqual(prevProps.location?.search, this.props.location?.search)) {
			await this.lookupRefFromParams();
		}
	}

	async lookupRefFromParams(): Promise<void> {
		const query = new URLSearchParams(this.props.location?.search);
		if (query.has('ref')) {
			let str = query.get('ref')!;
			str     = str.toUpperCase();
			str     = str.replace(/\s+/g, '');
			await new Promise(resolve => setInterval(() => {
				if (this.state.dataSourceInitDone) {
					resolve(true);
				}
			}, 100));
			return SWHelper.getPackageByRef(+str).then(async (result: StarPackage) => {
				if (result) {
					if (SWHelper.isSolo(result)) {
						SWHelper.selectSolo(result, (v: number) => this.props.setSelectedPackage({...result, v}));
					}
					if (SWHelper.isTwin(result)) {
						return SWHelper.selectTwin(result as Twin, this.props);
					}
				}
			});
		}
	}

	componentDidMount() {
		// @ts-ignore
		import('./assets/js/stellarium-web-engine.wasm').then(f => {
			try {
				this.initStelWebEngine(f.default, this.canvasRef.current, async () => {
					// Start auto location detection (even if we don't use it)
					await SWHelper.getGeolocation().then((p: any) => SWHelper.geoCodePosition(p)).then((loc: any) => {
						this.props.setAutoDetectedLocation(loc);
						this.props.setUseAutoLocation(true);
					}, (error: any) => {
						console.log(error)
					});
					SWHelper.$stel.setFont('regular', 'fonts/Roboto-Regular.ttf', 1.38);
					SWHelper.$stel.setFont('bold', 'fonts/Roboto-Bold.ttf', 1.38);
					SWHelper.$stel.core.constellations.show_only_pointed = false;
					if (!this.state.dataSourceInitDone) {
						// Set all default data sources
						const core = SWHelper.$stel.core;
						core.stars.addDataSource({url: 'skydata/stars'})
						core.skycultures.addDataSource({
							url: 'skydata/skycultures/western',
							key: 'western'
						})
						core.dsos.addDataSource({url: 'skydata/dso'})
						core.landscapes.addDataSource({
							url: 'skydata/landscapes/guereins',
							key: 'guereins'
						})
						core.milkyway.addDataSource({url: 'skydata/surveys/milkyway'})
						core.minor_planets.addDataSource({
							url: 'skydata/mpcorb.dat',
							key: 'mpc_asteroids'
						})
						core.planets.addDataSource({
							url: 'skydata/surveys/sso/moon',
							key: 'moon'
						})
						core.planets.addDataSource({url: 'skydata/surveys/sso/sun', key: 'sun'})
						core.planets.addDataSource({
							url: 'skydata/surveys/sso/moon',
							key: 'default'
						})
						core.comets.addDataSource({
							url: 'skydata/CometEls.txt',
							key: 'mpc_comets'
						})
						core.satellites.addDataSource({
							url: 'skydata/tle_satellite.jsonl.gz',
							key: 'jsonl/sat'
						})
					}
					this.setState({dataSourceInitDone: true});
					await this.lookupRefFromParams();
				}, () => {
					this.setState((state, props) => {
						let timeRef = state.timeRef;
						if (!timeRef) {
							timeRef = new DDDate().getMJD();
						}
						const d = new DDDate().getMJD();
						if (d !== timeRef) {
							const newUTC                = SWHelper.$stel.observer.utc + (props.timeSpeed * (d - timeRef));
							SWHelper.$stel.observer.utc = newUTC;
						}
						return {timeRef: d};
					})
				})
			} catch (e) {
				this.props.toggleWasmSupport(false);
			}
		})
	}

	initStelWebEngine(wasmFile: string, canvasElem: HTMLElement, callBackOnDone: () => void, onBeforeRendering: () => void) {
		StelWebEngine({
			wasmFile: wasmFile,
			canvas: canvasElem,
			translateFn: function(domain: string, str: string) {
				return str;
				// return i18next.t(str, {ns: domain});
			},
			onReady: (lstel: any) => {
				lstel.onBeforeRendering = onBeforeRendering;
				this.props.updateStel(lstel.getTree());
				const updateStel          = (path: any, value: any) => {
					const tree = {...this.props.stel};
					_.set(tree, path, value);
					this.props.updateStel(tree);
				};
				const shouldIgnoreUpdate  = (path: string) => ['progress', 'yaw', 'fps', 'tt'].find(p => path.includes(p));
				const throttledUpdateStel = _.throttle(updateStel, 500, {trailing: true});
				lstel.onValueChanged((path: any, value: any) => {
					if (shouldIgnoreUpdate(path)) {
						return;
					}
					if (path.includes('utc')) {
						throttledUpdateStel(path, value);
					} else if (_.get(this.props.stel, path) !== value) {
						updateStel(path, value);
					}
					const selection        = SWHelper.$stel.core.selection;
					const lastSelection    = SWHelper.$lastSelectionSweObj;
					const selectionChanged = SWHelper.$stel && !deepEqual(selection, lastSelection);
					if (selectionChanged) {
						if (SWHelper.$lastSelectionCustomSweObj && (SWHelper.$lastSelectionCustomSweObj.v !== selection?.v)) {
							try {
								SWHelper.$selectionLayer.remove(SWHelper.$lastSelectionCustomSweObj);
								SWHelper.$lastSelectionCustomSweObj = null;
							} catch {

							}
						}
						this.props.setSelection(selection);
						SWHelper.$lastSelectionSweObj = selection;
					}
				});
				SWHelper.$stel                                    = lstel;
				SWHelper.$stel.core.constellations.labels_visible = false;
				SWHelper.$stel.core.minor_planets.hints_visible   = false;
				SWHelper.$stel.core.dsos.visible                  = false;
				SWHelper.$selectionLayer                          = lstel.createLayer({
					id: 'slayer',
					z: 50,
					visible: true
				});
				SWHelper.$observingLayer                          = lstel.createLayer({
					id: 'obslayer',
					z: 40,
					visible: true
				});
				SWHelper.$skyHintsLayer                           = lstel.createLayer({
					id: 'skyhintslayer',
					z: 38,
					visible: true
				});
				return callBackOnDone();
			}
		})
	}

	storeCurrentLocation(currentLoc: GeoLocation, autoDetectedLocation: GeoLocation | undefined) {
		if (!SWHelper.$stel) {
			return;
		}
		const loc                              = autoDetectedLocation || currentLoc;
		const DD2R                             = Math.PI / 180;
		SWHelper.$stel.core.observer.latitude  = loc.lat * DD2R;
		SWHelper.$stel.core.observer.longitude = loc.lng * DD2R;
		SWHelper.$stel.core.observer.elevation = loc.alt;
		SWHelper.$stel.core.fov                = 120 * Math.PI / 180;
		// At startup, we need to wait for the location to be set before deciding which
		// startup time to set so that it's night time.
		this.setState((state) => {
			if (!state.startTimeIsSet || !autoDetectedLocation) {
				SWHelper.setTimeAfterSunset();
			}
			return {startTimeIsSet: true, initDone: true};
		});

		this.props.initComplete(true);
	}
}

const mapStatetoProps = (state: StoreState) => {
	return {
		currentLocation: state.currentLocation,
		autoDetectedLocation: state.autoDetectedLocation,
		useAutoLocation: state.useAutoLocation,
		timeZone: state.timeZone,
		timeSpeed: state.timeSpeed,
		selection: state.selection,
		stel: state.stel
	};
};

const mapDispatchToProps: AppDispatchProps = {
	initComplete,
	setCurrentLocation,
	setAutoDetectedLocation,
	setUseAutoLocation,
	toggleWasmSupport,
	updateStel,
	setSelection,
	setSelectedPackage
};
export default connect(mapStatetoProps, mapDispatchToProps)(App);
