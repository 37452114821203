import {connect} from 'react-redux';
import {GeoLocation, StoreState} from '../../redux/store/storeType';
import {
	setAutoDetectedLocation,
	setCurrentLocation,
	setTimeZone,
	setUseAutoLocation,
	toggleHideNav
} from '../../redux/actions';
import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import SWHelper from '../../assets/sw_helpers';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import './locationDialog.scss';
import {StellarAction} from '../../redux/constants';
// @ts-ignore
import geotz from 'geo-tz';
import {withTranslation} from 'react-i18next';
import {i18n, TFunction} from 'i18next';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const geocoder = new MapboxGeocoder({
	accessToken: 'pk.eyJ1IjoidGVjaC1saW5rIiwiYSI6ImNrbHRtbHF3YjByYTkycHAzc2R0OTQyMjYifQ.Edo02_nc3BJHQ5T8j7kmug',
	placeholder: 'Enter a location',
	types: 'region,postcode,district,place,address,locality',
	language: window.location.pathname.replace('/', ''),
});

interface LocationDialogState {
	showGeocoder: boolean;
	attachedGeocoder: boolean;
	resultListen: boolean;
	geoLocation: any;
	showTooltip: boolean;
}

interface LocationDialogProps {
	setCurrentLocation: any;
	setTimeZone: any;
	setAutoDetectedLocation: any;
	setUseAutoLocation: any;
	currentLocation: GeoLocation;
	toggleHideNav: (open: boolean) => StellarAction<boolean>;
	useAutoLocation: boolean;
	autoDetectedLocation: GeoLocation;
	t: TFunction;
	i18n: i18n;
}

class LocationDialog extends React.Component<LocationDialogProps, LocationDialogState> {
	private readonly mapRef: React.RefObject<HTMLDivElement>;
	private readonly buttonRef: React.RefObject<HTMLButtonElement>;

	constructor(props: any) {
		super(props);
		this.state     = {
			showGeocoder: false,
			attachedGeocoder: false,
			resultListen: false,
			geoLocation: null,
			showTooltip: false,
		}
		this.buttonRef = React.createRef();
		this.mapRef    = React.createRef();
	}

	onSelected(result: any): void {
		const location = this.formatGeoPlaceResult(result);
		this.props.setUseAutoLocation(false);
		this.props.setCurrentLocation(location);
		const timeZone = geotz(location!.lat, location!.lng)?.[0];
		this.props.setTimeZone(timeZone);
	}

	render() {
		return (
			<div id="location-dialog" style={{position: 'fixed', bottom: '10px', left: '10px'}}>
				<OverlayTrigger
					placement="right"
					delay={{show: 250, hide: 400}}
					overlay={(props: any) => (
						<Tooltip id="button-tooltip" {...props}>
							Use current location
						</Tooltip>
					)}
				>
					<Button onClick={() => this.geoCodeLocation()}
					        target={this.buttonRef}
					        onMouseLeave={() => this.setState({showTooltip: false})}
					        onMouseEnter={() => this.setState({showTooltip: true})}><i
						className="bi-geo-alt-fill text-white"/>
						&nbsp;{!this.props.useAutoLocation ? this.props.currentLocation?.short_name : this.props.autoDetectedLocation?.short_name}
					</Button>
				</OverlayTrigger>
				{!this.state.showGeocoder ? (<Button variant="light" onClick={() => this.toggleCustomSearch()}>
					<i className="bi-search"/>
				</Button>) : null}
				{this.state.showGeocoder ? (
					<>
						<Card id="geo-card">
							<Card.Body className="position-relative">
								<Card.Title className="font-weight-bold">{this.props.t('Find a Location')}</Card.Title>
								<Button id="close-loc-btn" variant="light" onClick={() => this.toggleCustomSearch()}>
									<i className="bi-x"/>
								</Button>
								<br/>
								<div id="geocoder-container" ref={this.mapRef}/>
							</Card.Body>
						</Card>
					</>) : null}
			</div>);
	}

	formatGeoPlaceResult(res: any): GeoLocation | null {
		let result = res;
		if (result['result']) {
			result = result.result;
		} else {
			result = result.features[0];
		}
		const {id, place_name, center, context, text, place_type} = result;
		const geoLocation: Partial<GeoLocation>                   = {};
		const types                                               = ['district', 'country', 'region', 'place', 'locality'];
		if (context) {
			for (const t of types) {
				const ctx = (context as any[]).find(ctx => ctx.id.includes(t));
				if (ctx) {
					(geoLocation as any)[t] = ctx.text;
				} else if (id.includes(t)) {
					if (t === 'place') {
						(geoLocation as any)['city'] = text;
					}
					(geoLocation as any)[t]           = text;
					(geoLocation as any)['placeType'] = place_type[0];
					if (t === 'country') {
						(geoLocation as any)['countryCode'] = ctx.short_code;
					}
				}
			}
		}
		return {
			...geoLocation as GeoLocation,
			short_name: place_name,
			street_address: place_name,
			lat: center[1],
			lng: center[0]
		} as GeoLocation;
	}

	toggleCustomSearch(): void {
		this.setState(({showGeocoder, attachedGeocoder}) => {
			const shouldShow = !showGeocoder;
			const isAttached = shouldShow && attachedGeocoder;
			return {showGeocoder: shouldShow, attachedGeocoder: isAttached};
		});
		setTimeout(() => {
			if (this.mapRef?.current && !this.state.attachedGeocoder) {
				geocoder.addTo('#geocoder-container');
				geocoder.setPlaceholder(this.props.t('Enter a location'));
				geocoder.setLanguage(this.props.i18n.language);
				if (window?.matchMedia('(max-width: 800px)')?.matches) {
					this.props.toggleHideNav(this.state.showGeocoder);
				}
				if (!this.state.resultListen) {
					geocoder.on('result', this.onSelected.bind(this))
				}
				this.setState({attachedGeocoder: true, resultListen: true});
			}
			if (!this.mapRef?.current) {
				this.props.toggleHideNav(false);
			}
		}, 100);
	}

	geoCodeLocation(): Promise<void> {
		return SWHelper.getGeolocation().then((p: any) => SWHelper.geoCodePosition(p)).then((loc: any) => {
			this.props.setAutoDetectedLocation(loc);
			this.props.setUseAutoLocation(true);
			SWHelper.setTime(new Date());
			SWHelper.setTimeAfterSunset();
		}, (error: any) => {
			console.log(error)
		});
	}

}

const mapStateToProps = (state: StoreState) => ({
	currentLocation: state.currentLocation,
	useAutoLocation: state.useAutoLocation,
	autoDetectedLocation: state.autoDetectedLocation
});

const mapDispatchToProps = {
	setCurrentLocation,
	setAutoDetectedLocation,
	setUseAutoLocation,
	toggleHideNav,
	setTimeZone
};

export default withTranslation('Content')(connect(mapStateToProps, mapDispatchToProps)(LocationDialog));
