import {StellarAction} from '../constants';
import {GeoLocation} from '../store/storeType';
import {SweObj} from '../../models/swe.model';
import {StarPackage} from '../../models/star-package.model';
import {Solo} from '../../models/solo.model';
import {SkyModel} from '../../models/skymodel.model';

export function toggleWasmSupport(newValue: boolean): StellarAction<boolean> {
	return {
		type: 'TOGGLE_WASM_SUPPORT',
		payload: newValue
	}
}

export function toggleHideNav(newValue: boolean): StellarAction<boolean> {
	return {
		type: 'TOGGLE_HIDE_NAV',
		payload: newValue
	}
}

export function setTimeZone(newValue: string): StellarAction<string> {
	return {
		type: 'SET_TIME_ZONE',
		payload: newValue
	}
}

export function updateStel(newValue: any): StellarAction<any> {
	return {
		type: 'UPDATE_STEL',
		payload: newValue
	}
}

export function setUseAutoLocation(newValue: boolean): StellarAction<boolean> {
	return {
		type: 'SET_USE_AUTO_LOCATION',
		payload: newValue
	};
}

export function setAutoDetectedLocation(newValue: GeoLocation): StellarAction<GeoLocation> {
	return {
		type: 'SET_AUTO_LOCATION',
		payload: newValue
	};
}

export function setCurrentLocation(newValue: GeoLocation): StellarAction<GeoLocation> {
	return {
		type: 'SET_CURRENT_LOCATION',
		payload: newValue
	}
}

export function setSelectedPackage(newValue: StarPackage): StellarAction<StarPackage> {
	return {
		type: 'SET_SELECTED_PACKAGE',
		payload: newValue
	}
}

export function setSelection(newValue: (SweObj & SkyModel) | (SweObj & Solo) | null): StellarAction<(SweObj & SkyModel) | (SweObj & Solo) | null> {
	return {
		type: 'SET_SELECTION',
		payload: newValue
	}
}

export function setSearchFocused(newValue: boolean): StellarAction<boolean> {
	return {
		type: 'SET_SEARCH_FOCUSED',
		payload: newValue
	}
}

export function initComplete(newValue: boolean): StellarAction<boolean> {
	return {
		type: 'INIT_COMPLETE',
		payload: newValue
	}
}


