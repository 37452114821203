import React from 'react';
import {ListGroup, ListGroupItem} from 'react-bootstrap';
import {LanguageDropdownItem} from '../../models/language-dropdown-item';
import './language-dropdown.scss';
import Button from 'react-bootstrap/Button';

interface LanguageDropdownProps {
	lang: string;
	closeDropdown: () => void;
	dropdownList: LanguageDropdownItem[];
}

class LanguageDropdown extends React.Component<LanguageDropdownProps> {
	getFlag(flag: string): string {
		return `/flags/${flag}.svg`;
	}

	render() {
		return (
			<>
				<ListGroup className="bg-dark shadow-lg" id="language-dropdown">
					<Button id="close-btn" className="bg-transparent border-0"
					        onClick={() => this.props.closeDropdown()}><i
						className="bi-x-circle text-white"/>
					</Button>
					{this.props.dropdownList.map(item => (
						<ListGroupItem
							className={(this.props.lang === item.key ? 'bg-white ' : 'bg-dark ') + 'd-flex flex-row align-items-center align-content-center justify-content-start position-relative'}
							action
							key={item.key}
							onClick={() => item.onClick()}>
							<div style={{verticalAlign: 'middle'}} className="d-inline-flex mr-4">
								<img src={this.getFlag(item.key)} width="24px" alt={item.label}/>
							</div>
							<div
								className={(this.props.lang === item.key ? 'text-black ' : 'text-white ') + 'd-inline-block m-0 flex-column star-info'}>
								<div>
									<strong className="star-name">{item.label}</strong>
								</div>
							</div>
						</ListGroupItem>
					))}
				</ListGroup></>);
	}
}

export default LanguageDropdown;
