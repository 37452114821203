import React, {useState} from 'react';
import i18n from '../../util/i18n';
import {useTranslation} from 'react-i18next';
import {LanguageDropdownItem} from '../../models/language-dropdown-item';
import LanguageDropdown from '../language-dropdown/language-dropdown';
import Button from 'react-bootstrap/Button';
import {useHistory} from 'react-router';
import './language.scss';
import {StoreState} from '../../redux/store/storeType';
import {setAutoDetectedLocation} from '../../redux/actions';
import {connect} from 'react-redux';
import SWHelper from '../../assets/sw_helpers';
import {SupportedLanguage} from '../../models/supported-language.enum';

const Language: React.FC = (props: any) => {
	const {t}     = useTranslation('Language');
	const history = useHistory();

	const changeLanguage = async (language: SupportedLanguage) => {
		history.push(language);
		return i18n.changeLanguage(language)
			.then(() => SWHelper.getGeolocation())
			.then((p: any) => SWHelper.geoCodePosition(p))
			.then((loc: any) => {
				props.setAutoDetectedLocation(loc);
				SWHelper.setTime(new Date());
				SWHelper.setTimeAfterSunset();
			}, (error: any) => {
				console.log(error)
			});
	};

	const languageDropdown: LanguageDropdownItem[] = [
		{
			key: SupportedLanguage.English,
			label: t('English'),
			onClick: () => changeLanguage(SupportedLanguage.English)
		},
		{
			key: SupportedLanguage.French,
			label: t('French'),
			onClick: () => changeLanguage(SupportedLanguage.French)
		},
		{
			key: SupportedLanguage.Spanish,
			label: t('Spanish'),
			onClick: () => changeLanguage(SupportedLanguage.Spanish)
		},
		{
			key: SupportedLanguage.German,
			label: t('German'),
			onClick: () => changeLanguage(SupportedLanguage.German)
		}
	];


	const [dropdownOpen, setDropdownState] = useState(false);

	return (<>
		<div id="language-selector">
			<Button className="bg-dark border-dark" onClick={() => setDropdownState(true)}><i
				className="bi bi-globe text-white"/>
				&nbsp;{window?.matchMedia('(max-width: 800px)')?.matches ? '' : 'Language: '}{languageDropdown.find(item => item.key === i18n.language)?.label || t('English')}
			</Button>
		</div>
		{dropdownOpen ? (
			<LanguageDropdown
				lang={i18n.language}
				dropdownList={languageDropdown}
				closeDropdown={() => setDropdownState(false)}/>) : null}
	</>);
};

const mapStateToProps = (state: StoreState) => ({
	autoDetectedLocation: state.autoDetectedLocation
});

const mapDispatchToProps = {
	setAutoDetectedLocation,
};

export default connect(mapStateToProps, mapDispatchToProps)(Language);
