import firebase from 'firebase/app';
import 'firebase/firestore';

const firebaseConfig = {
	apiKey: 'AIzaSyCYTrwkjre7wjlMWWZzjl3oXD_l3G2c42E',
	authDomain: 'star-finder-5c5a9.firebaseapp.com',
	projectId: 'star-finder-5c5a9',
	storageBucket: 'star-finder-5c5a9.appspot.com',
	messagingSenderId: '201669839350',
	appId: '1:201669839350:web:80028e79b194ed90705fd7',
	measurementId: 'G-31LMX6NHVH'
};
let init = false;
if (!init) {
	firebase.initializeApp(firebaseConfig);
	init = true;
}
export const SOLO_DB = firebase.firestore().collection('SOLO');
export const HIP_DB = firebase.firestore().collection('HIP');
export const TWIN_DB = firebase.firestore().collection('TWIN');
export default firebase;

